import React, { Component } from "react"
import SimpleSlider from "react-slick"
import Slide from "../slider-carousel/slide"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"
import "../slider-carousel/slider-carousel.css"
import "./blog-slider.css"
class BlogSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }
  render() {
    var sliderGallerySettings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 800,
      dots: true,
      slide: "row",
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      slickCurrentSlide: 1,
      initialSlide: 1,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),

      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 1,
            centerMode: false,
          },
        },

        {
          breakpoint: 1084,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
      className: "blog-media fl-wrap nomar-bottom",
    }
    return (
      <div className="single-slider-wrap slider-carousel-wrap ">
        <div className="single-slider cur_carousel-slider-container fl-wrap">
          <SimpleSlider
            ref={slider => (this.slider = slider)}
            {...sliderGallerySettings}
          >
            {this.props.slides.map((slide, idx) => {
              return <Slide key={idx} slide={slide} />
            })}
          </SimpleSlider>
          <div
            className="sp-cont sp-arr sp-cont-prev"
            style={{ backgroundColor: "grey" }}
            onClick={this.previous}
          >
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          </div>
          <div
            className="sp-cont sp-arr sp-cont-next"
            style={{ backgroundColor: "grey" }}
            onClick={this.next}
          >
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </div>
        </div>
        {/* <div className="fet_pr-carousel-counter">
          {this.state.activeSlide + 1} / {this.props.slides.length}
        </div> */}
      </div>
    )
  }
}

export default BlogSlider
