import React from "react"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Parallax } from "react-scroll-parallax"
import BlogSlider from "../blog/blog-slider"
import SecLines from "../sec-lines/sec-lines"
import BlogNavigation from "../blog/blog-navigation"
import GetInTouch from "../get-in-touch/get-in-touch"
import Sticky from "react-stickynode"
import useWindowSize from "../../hooks/useWindowSize"
import PortfolioMasonry from "./PortfolioMasonry"

const YoutubeVideo = (url, index) => {
  return (
    <div key={index} className="fl-wrap vertical-gallery">
      <iframe
        width="560"
        height="315"
        src={`${url}?controls=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

const PortfolioSingle = props => {
  const { data } = props
  const { project } = data
  const { width, height } = useWindowSize()
  return (
    <div className="content project-details">
      <div className="single-page-decor"></div>
      <div className="single-page-fixed-row">
        <div className="scroll-down-wrap">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
          <span>Runter scrollen</span>
        </div>
        <Link
          to="/projects"
          target="_self"
          className="single-page-fixed-row-link"
        >
          <i className="fal fa-arrow-left"></i> <span>Zurück</span>
        </Link>
      </div>

      <section
        className="parallax-section dark-bg sec-half parallax-sec-half-right"
        data-scrollax-parent="true"
      >
        <Parallax
          className="bg par-elem"
          y={[-20, 50]}
          styleInner={{
            color: "red",
            fontSize: "125px",
          }}
        >
          <img
            src={project.frontmatter.featuredImage.childImageSharp.fluid.src}
            className="respimg"
            alt={project.frontmatter.name}
          />
        </Parallax>
        <div className="overlay"></div>
        <div className="pattern-bg"></div>
        <div className="container">
          <div className="section-title">
            <h1
              dangerouslySetInnerHTML={{ __html: project.frontmatter.name }}
            ></h1>
            {project.frontmatter.goals ? (
              <p>{project.frontmatter.goals}</p>
            ) : (
              <p>{project.excerpt}</p>
            )}

            <div className="horizonral-subtitle">
              <span>PROJEKTE</span>
            </div>
          </div>
          <Link
            to="#sec1"
            className="custom-scroll-link hero-start-link"
            onClick={() => {
              scrollTo("#sec1")
            }}
          >
            <span>Los geht's</span> <i className="fal fa-long-arrow-down"></i>
          </Link>
        </div>
      </section>

      <section data-scrollax-parent="true" id="sec1">
        <div
          className="section-subtitle right-pos"
          data-scrollax="properties: { translateY: '-250px' }"
        >
          <span>//</span>Project Title
        </div>
        <div className="container">
          <div className="fl-wrap">
            <div className="row">
              <div className="col-md-4">
                <Sticky
                  enabled={width >= 768 ? true : false}
                  top={100}
                  innerZ={34}
                >
                  <div className="fixed-column l-wrap">
                    <div className="pr-title fl-wrap">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: project.frontmatter.name,
                        }}
                      ></h3>
                      <span>{project.excerpt}</span>
                    </div>
                    <div className="ci-num">
                      <span>01.</span>
                    </div>
                  </div>
                </Sticky>
              </div>
              <div className="col-md-8">
                <div className="details-wrap fl-wrap">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: project.frontmatter.name,
                    }}
                  ></h3>
                  <div className="parallax-header">
                    <span>Kategorie : </span>
                    {project.frontmatter && project.frontmatter.categories ? (
                      project.frontmatter.categories.map((category, idx) => {
                        return (
                          <Link
                            key={idx}
                            to="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            {category}
                          </Link>
                        )
                      })
                    ) : (
                      <Link to="/">{project.frontmatter.category}</Link>
                    )}
                  </div>
                  {project.frontmatter.masonry ? (
                    <PortfolioMasonry
                      name={project.frontmatter.name}
                      items={project.frontmatter.slides}
                    />
                  ) : project.frontmatter.slides &&
                    project.frontmatter.slides.length > 0 ? (
                    <div className="fl-wrap vertical-gallery">
                      <BlogSlider slides={project.frontmatter.slides} />
                    </div>
                  ) : (
                    ""
                  )}

                  {project.frontmatter.videoUrl &&
                  new RegExp("^https://player.vimeo.com", "i").test(
                    project.frontmatter.videoUrl
                  ) ? (
                    <div className="fl-wrap vertical-gallery">
                      <iframe
                        src={project.frontmatter.videoUrl}
                        width="640"
                        height="360"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen
                      ></iframe>
                    </div>
                  ) : (
                    ""
                  )}

                  {project.frontmatter.videoUrl &&
                  new RegExp("^https://www.youtube.com/", "i").test(
                    project.frontmatter.videoUrl
                  ) ? (
                    <div className="fl-wrap vertical-gallery">
                      <iframe
                        width="560"
                        height="315"
                        src={`${project.frontmatter.videoUrl}?controls=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : (
                    ""
                  )}

                  {project.frontmatter.videoUrls &&
                  new RegExp("^https://www.youtube.com/", "i").test(
                    project.frontmatter.videoUrls[0]
                  )
                    ? project.frontmatter.videoUrls.map((url, index) => {
                        return YoutubeVideo(url, index)
                      })
                    : ""}

                  <div className="clearfix"></div>
                </div>
                <div className="pr-list fl-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <ul>
                        {project.frontmatter.date ? (
                          <li>
                            <span>Datum :</span> {project.frontmatter.date}
                          </li>
                        ) : (
                          ""
                        )}
                        {project.frontmatter.client ? (
                          <li>
                            <span>Kunde :</span> {project.frontmatter.client}
                          </li>
                        ) : (
                          ""
                        )}
                        {project.frontmatter.skills ? (
                          <li>
                            <span>Skills :</span>
                            <ol className="skills">
                              {project.frontmatter.skills.map((skill, idx) => {
                                return <li key={idx}>{skill}</li>
                              })}
                            </ol>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion mar-top">
                  <a
                    to="#"
                    className="toggle act-accordion"
                    onClick={e => {
                      e.preventDefault()
                    }}
                  >
                    Details
                  </a>
                  <div className="accordion-inner visible">
                    <div
                      className="portfolio-content"
                      dangerouslySetInnerHTML={{ __html: project.html }}
                    ></div>
                  </div>
                </div>

                {project.frontmatter.projectUrl ? (
                  <a
                    href={project.frontmatter.projectUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn float-btn flat-btn color-btn mar-top"
                  >
                    Projekt öffnen
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="limit-box fl-wrap"></div>
          </div>

          <BlogNavigation
            title="Projects"
            pageContext={props.pageContext}
            back="/projects"
          />
        </div>

        <SecLines />
      </section>

      <GetInTouch />
    </div>
  )
}

export default PortfolioSingle
