import React from "react"
import PortfolioMasonryItem from "./PortfolioMasonryItem"
import Masonry from "react-masonry-component"

const PortfolioMasonry = ({ items, name }) => {
  return (
    <>
      <div className="fl-wrap">
        <Masonry className="gallery-items min-pad lightgallery three-column">
          {items.map((item, index) => {
            return (
              <PortfolioMasonryItem
                key={index}
                alt={name}
                image={item.childImageSharp.fluid.src}
                thumbnail={item.childImageSharp.fluid.src}
              />
            )
          })}
          )
        </Masonry>
      </div>
      <div className="clearfix"></div>
    </>
  )
}

export default PortfolioMasonry
