import React from "react"
import { LightgalleryItem } from "react-lightgallery"

const stripHTML = string => {
  return string.replace(/<[^>]*>?/gm, "")
}
const PortfolioMasonryItem = ({ alt, thumbnail, image }) => {
  return (
    <div className="gallery-item web branding">
      <LightgalleryItem group="project" src={image}>
        <div className="grid-item-holder">
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
            }}
            className="fet_pr-carousel-box-media-zoom popup-image"
          >
            <i className="fal fa-search"></i>
          </a>
          <img src={thumbnail} alt={stripHTML(alt)} />
        </div>
      </LightgalleryItem>
    </div>
  )
}

export default PortfolioMasonryItem
