import React, { ReactNode, useCallback } from "react"
import TransitionLink from "gatsby-plugin-transition-link"

interface Props {
  children: ReactNode
  node: ReactNode
  to: any
  className: String
  title: String
}

export const LinkSlideRight = (props: Props) => {
  const exitTransition = {
    length: 0.5,
    zIndex: 2,
    trigger: ({ node }) => {
      exitTransition.exitTrigger(node)
      node.style.top = -window.pageYOffset + "px"
      window.scrollTo({ top: -window.pageYOffset })
    },
    exitTrigger: useCallback(container => {
      container.setAttribute(
        "style",
        "animation: slideToRight 0.5s cubic-bezier(0.83, 0, 0.17, 1) forwards;"
      )
    }, []),
  }

  const entryTransition = {
    zIndex: 1,
    trigger: ({ node }) => {
      entryTransition.entryTrigger(node)
    },
    entryTrigger: useCallback(container => {
      container.setAttribute(
        "style",
        "animation: slideFromRight 0.5s cubic-bezier(0.83, 0, 0.17, 1) forwards;"
      )
    }, []),
  }

  return (
    <>
      <TransitionLink
        to={props.to}
        title={props.title}
        exit={exitTransition}
        entry={entryTransition}
        className={props.className}
      >
        {props.children}
      </TransitionLink>
    </>
  )
}

export default LinkSlideRight
