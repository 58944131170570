import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PortfolioSingle from "../components/portfolio/PortfolioSingle"
import SEO from "../components/seo"

const ProjectTemplate = props => {
  const { location, data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={data.project.frontmatter.name}
        description={`Project -${
          data.project.frontmatter.goals || data.project.frontmatter.client
        }`}
      />

      <PortfolioSingle {...props} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site: site {
      siteMetadata {
        title
        author
      }
    }
    project: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        name
        client
        skills
        projectUrl
        videoUrl
        videoUrls
        goals
        masonry
        slides {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featured
        categories
        category
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
            }
          }
        }
        date(formatString: "YYYY, MM")
        description
      }
      fields {
        slug
        category
        tags
      }
    }
  }
`

export default ProjectTemplate
