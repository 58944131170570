import React from "react"
import LinkLeft from "../../utils/transitionLinks/LinkSlideLeft"
import LinkRight from "../../utils/transitionLinks/LinkSlideRight"

const BlogNavigation = props => {
  const { pageContext, title, back } = props
  const { previous, next } = pageContext
  const previousLink = previous !== null ? previous : back ? back : "/"
  const nextLink = next !== null ? next : back ? back : "/"
  return (
    <div className="content-nav">
      <ul>
        <li>
          <LinkRight to={previousLink} className="ln" title="Zurück">
            <i className="fal fa-arrow-left"></i>
            <span className="tooltip">{previous ? "Zurück" : "Zum Start"}</span>
          </LinkRight>
        </li>
        <li>
          <span className="cur-page">
            <span>{title}</span>
          </span>
        </li>
        <li>
          <LinkLeft cover to={nextLink} className="rn" title="Weiter">
            <i className="fal fa-arrow-right"></i>
            <span className="tooltip">{next ? "Weiter" : "Zum Start"}</span>
          </LinkLeft>
        </li>
      </ul>
    </div>
  )
}

export default BlogNavigation
